








































































































import {Component, Mixins} from 'vue-property-decorator';
import Headquarter from "@/models/Headquarter";
import {MultipleItem, SingleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";
import SessionMixin from "@/mixins/SessionMixin";
import AccountBoxComponent from "@/components/AccountBoxComponent.vue";
import HeadquarterOrder, {Status} from "@/models/HeadquarterOrder";
import RulesTool from "@/services/tool/RulesTool";
import Address from "@/models/Address";
import Product from "@/models/Product";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import HeadquarterOrdersTab from "@/components/tabs/HeadquarterOrdersTab.vue";
import HeadquarterOrderDataComponent from "@/components/HeadquarterOrderDataComponent.vue";
import CartModule from "@/store/CartModule";
import ProductService from "@/services/ProductService";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import HeadquarterOrderDetail from "@/models/HeadquarterOrderDetail";
import HeadquarterOrderService from "@/services/HeadquarterOrderService";
import CartTool from "@/services/tool/CartTool";
import OrderCreatedDialog from "@/components/dialogs/OrderCreatedDialog.vue";

@Component({
  components: {OrderCreatedDialog, HeadquarterOrderDataComponent, HeadquarterOrdersTab, AccountBoxComponent}
})
export default class CheckoutHeadquarterOrder extends Mixins(SessionMixin) {

  get rules() {return RulesTool }
  get lang() { return getModule(LangModule).lang }
  get cart() { return getModule(CartModule).cart }

  dialog: boolean = false
  loading: boolean = false
  headquarterDialog: boolean = false

  products: MultipleItem<Product> = { totalItems: 0, items: [] }
  headquarterOrder: SingleItem<HeadquarterOrder> =  { item: new HeadquarterOrder() }
  headquarters: MultipleItem<Headquarter> = { items: [], totalItems: 0 }
  addresses: MultipleItem<Address> = {items: [], totalItems: 0}
  addressDialog: boolean = false

  headers = [
    {text: this.lang.products, align: 'center', value: 'product', width: 'auto'},
    {text: this.lang.quantity, align: 'center', value: 'quantity', width: 'auto'},
    {text: this.lang.commerciaRate, align: 'center', value: 'commercialRate', width: 'auto'},
    {text: this.lang.taxBaseHeader, align: 'center', value: 'taxBase', width: 'auto'},
  ]

  created() {
    this.refresh()
  }

  async refresh() {
    await Handler.getItems(this, this.products, () => ProductService.getProductsById2(this.cart))
  }

  async createOrderConfirmation() {
    if (this.validateOrder()) {
      getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.orderConfirmation, async () => {
        this.populateOrder()
        await this.createOrder()
        this.dialog = true
        CartTool.removeAllProducts()
      }))
    }
  }

  getTotalTaxBase(): number {
    let totalTaxBase: number = 0
    this.products.items.forEach(product => {
      totalTaxBase += Number(product.commercialRate) * Number(product.quantity)
    })

    return Number(totalTaxBase.toFixed(2))
  }

  validateOrder() {
    // @ts-ignore
    return this.$refs.orderDataTab.validateForm()
  }

  async createOrder() {
    await Handler.getItem(this, this.headquarterOrder, () => HeadquarterOrderService.create(this.headquarterOrder.item))
  }

  populateOrder() {
    this.headquarterOrder.item.headquarterOrderDetails = []
    this.products.items.forEach(e => {
      const orderDetail = new HeadquarterOrderDetail()
      orderDetail.quantity = e.quantity
      orderDetail.product = e
      this.headquarterOrder.item.headquarterOrderDetails!.push(orderDetail)
    })
    this.headquarterOrder.item.status = Status.WAITING
  }

}
