













































































































































import {Component, Mixins} from 'vue-property-decorator';
import PaginationMixin from "@/mixins/PaginationMixin";
import LangMixin from "@/mixins/LangMixin";
import HeadquarterOrder, {Status} from "@/models/HeadquarterOrder";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Headquarter from "@/models/Headquarter";
import HeadquarterOrderService from "@/services/HeadquarterOrderService";
import Handler from "@/handlers/Handler";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import OrderStatusComponent from "@/components/OrderStatusComponent.vue";

@Component({
  components: {OrderStatusComponent}
})
export default class HeadquarterOrdersTab extends Mixins(PaginationMixin, LangMixin) {

  get lang() { return getModule(LangModule).lang }

  loading: boolean = false
  dialog: boolean = false
  picker: string = ""
  dates: string[] = [this.picker]

  headquarterOrderStatus: Status | null = null
  headquarterOrder: HeadquarterOrder = new HeadquarterOrder()
  headquarterOrders: MultipleItem<HeadquarterOrder> = { items: [], totalItems: 0 }

  headquarter: Headquarter = new Headquarter()
  headquarters: MultipleItem<Headquarter> = { items: [], totalItems: 0 }

  headers = [
    { text: "Referencia", align: 'center', value: 'reference', width: 'auto' },
    { text: "Fecha de compra", align: 'center', value: 'createdAt', width: 'auto' },
    { text: "Estado", align: 'center', value: 'status', width: 'auto' },
    { text: "Total", align: 'center', value: 'totalAmount', width: 'auto' }
  ]

  statuses = [
    { name: "En espera", value: Status.WAITING },
    { name: "Enviado", value: Status.SEND },
    { name: "Borrador", value: Status.DRAFT }
  ]

  indicators = [
    { name: "En progreso", color: "purple lighten-3" },
    { name: "Enviado", color: "blue lighten-2" },
    { name: "Por confirmar", color: "deep-purple lighten-1" },
    { name: "Retrasado", color: "grey" },
    { name: "Cancelado", color: "light-blue lighten-4" }
  ]

  created() {
    this.refresh()
  }

  refresh() {
    this.getHeadquarterOrders()
    this.setPageCount(this.headquarterOrders.totalItems!!)
  }

  async getHeadquarterOrders() {
    await Handler.getItems(this, this.headquarterOrders, () =>
        HeadquarterOrderService.findMyCompanyOrders(this.page - 1, this.size, this.search)
    )
  }

  rowClick(item: HeadquarterOrder) {
    this.headquarterOrder = item
    this.dialog = true
  }

}
