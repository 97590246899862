import {JsonObject, JsonProperty} from "json2typescript";
import Product from "@/models/Product";

@JsonObject("HeadquarterOrderDetail")
export default class HeadquarterOrderDetail {

    @JsonProperty("id", Number, true)
    id?: number = undefined

    @JsonProperty("quantity", Number, true)
    quantity?: number = undefined

    @JsonProperty("unitPrice", String, true)
    unitPrice?: string = undefined

    @JsonProperty("unitPricePvp", String, true)
    unitPricePvp?: string = undefined

    @JsonProperty("totalPvp", String, true)
    totalPvp?: string = undefined

    @JsonProperty("total", String, true)
    total?: string = undefined

    @JsonProperty("product", Product, true)
    product?: Product = undefined

}