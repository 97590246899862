




























import {Vue, Component, PropSync, Mixins} from 'vue-property-decorator';
import SessionMixin from "@/mixins/SessionMixin";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";

@Component({})
export default class OrderCreatedDialog extends Mixins(SessionMixin) {
  @PropSync('dialog') syncedDialog!: boolean

  lang: any = getModule(LangModule).lang

}
