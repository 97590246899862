import ConstantTool from "@/services/tool/ConstantTool";
import axios from "axios";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import Headquarter from "@/models/Headquarter";
import Response from "@/models/responses/Response";
import SnackbarModule from "@/store/SnackbarModule";

export default class HeadquarterService {

    static async getById(id: number): Promise<Response<Headquarter>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/headquarters/${id}`, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const headquarter = JsonTool.jsonConvert.deserializeObject(response.data, Headquarter)
            return Promise.resolve({ result: headquarter })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async findByMyCompany(page: number, size: number, search: Nullable<String>): Promise<Response<Headquarter[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/company/@me/headquarters`, {
                params: { page, size, search },
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const headquarter = JsonTool.jsonConvert.deserializeArray(response.data, Headquarter)
            return Promise.resolve({ result: headquarter })
        } catch (e) {
            return Promise.reject(e)
        }
    }


}