import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript";
import Auditing from "@/models/Auditing";
import Company from "@/models/Company";
import HeadquarterOrderDetail from "@/models/HeadquarterOrderDetail";
import {OrderType} from "@/models/Order";
import Address from "@/models/Address";
import Headquarter from "@/models/Headquarter";

export enum Status {
    WAITING = "WAITING",
    SEND = "SEND",
    FINISH = "FINISH",
    DRAFT = "DRAFT",
}

@JsonConverter
class StatusConverter implements JsonCustomConvert<Status> {

    deserialize(data: string): Status { return (<any>Status)[data] }
    serialize(data: Status): any { return data.toString() }

}

@JsonObject("HeadquarterOrder")
export default class HeadquarterOrder extends Auditing {

    @JsonProperty("id", Number, true)
    id?: number = undefined

    @JsonProperty("reference", String, true)
    reference?: string = undefined

    @JsonProperty("description", String, true)
    description?: string = undefined

    @JsonProperty("company", Company, true)
    company?: Company = undefined

    @JsonProperty("creationRate", Number, true)
    creationRate?: number = undefined

    @JsonProperty("total", String, true)
    total?: string = undefined

    @JsonProperty("totalPvp", String, true)
    totalPvp?: string = undefined

    @JsonProperty("headquarterOrderDetails", [HeadquarterOrderDetail], true)
    headquarterOrderDetails?: HeadquarterOrderDetail[] = []

    @JsonProperty("status", StatusConverter, true)
    status?: Status = undefined

    @JsonProperty("shippingAddress", Address, true)
    shippingAddress?: Address = undefined

    @JsonProperty("headquarter", Headquarter, true)
    headquarter?: Headquarter = undefined

}