var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex no-wrap",attrs:{"cols":"12"}},[_c('AccountBoxComponent',{attrs:{"user":_vm.session.user,"icon":"mdi-cart-outline"}})],1)],1),_c('div',{staticClass:"py-4"},[_c('h3',{staticClass:"font-weight-medium grey--text text--darken-2"},[_vm._v(_vm._s(_vm.lang.checkout))])]),_c('HeadquarterOrderDataComponent',{ref:"orderDataTab",attrs:{"address":_vm.headquarterOrder.item.shippingAddress,"headquarter":_vm.headquarterOrder.item.headquarter,"description":_vm.headquarterOrder.item.description,"reference":_vm.headquarterOrder.item.reference},on:{"update:address":function($event){return _vm.$set(_vm.headquarterOrder.item, "shippingAddress", $event)},"update:headquarter":function($event){return _vm.$set(_vm.headquarterOrder.item, "headquarter", $event)},"update:description":function($event){return _vm.$set(_vm.headquarterOrder.item, "description", $event)},"update:reference":function($event){return _vm.$set(_vm.headquarterOrder.item, "reference", $event)}}}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"my-2 d-flex justify-start align-center grey--text text--darken-1",attrs:{"color":"#E6E8E7","width":"100%"}},[_c('span',{staticClass:"my-3 mx-3 font-weight-bold"},[_vm._v(_vm._s(_vm.lang.orderSummary))])]),_c('v-data-table',{staticStyle:{"max-height":"600px","width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.products.items,"disable-pagination":"","disable-filtering":"","disable-sort":"","fixed-header":"","hide-default-footer":"","no-data-text":_vm.lang.noAvaliableOrders,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"header.product",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-3 py-2 body-1 font-weight-medium text-truncate"},[_vm._v(_vm._s(header.text))])]}},{key:"header.quantity",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-3 py-2 body-1 font-weight-medium text-truncate"},[_vm._v(_vm._s(header.text))])]}},{key:"header.taxBase",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-3 py-2 body-1 font-weight-medium text-truncate"},[_vm._v(_vm._s(header.text))])]}},{key:"header.commercialRate",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-3 py-2 body-1 font-weight-medium text-truncate"},[_vm._v(_vm._s(header.text))])]}},{key:"header.total",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-3 py-2 body-1 font-weight-medium text-truncate"},[_vm._v(_vm._s(header.text))])]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold grey--text text--darken-1"},[_vm._v(_vm._s(item.connectorId))])]}},{key:"item.commercialRate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold grey--text text--darken-1"},[_vm._v(_vm._s(Number(item.commercialRate).toFixed(2))+"€")])]}},{key:"item.taxBase",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold grey--text text--darken-1"},[_vm._v(_vm._s((Number(item.commercialRate) * item.quantity).toFixed(2))+"€")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold grey--text text--darken-1"},[_vm._v(_vm._s(item.quantity))])]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"grey darken--2 text-center body-1 font-weight-bold white--text"},[_c('td'),_c('td',[_c('h2',[_vm._v(_vm._s(_vm.products.items.reduce(function (sum, value) { return (typeof value.quantity == "number" ? sum + value.quantity : sum); }, 0)))])]),_c('td'),_c('td',[_c('h2',[_vm._v(_vm._s(_vm.getTotalTaxBase())+"€")])])])]},proxy:true}])}),_c('v-progress-linear',{attrs:{"indeterminate":_vm.loading}})],1)],1),_c('v-row',{attrs:{"justify":_vm.$vuetify.breakpoint.name == 'xs' ? 'center' : 'space-between'}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"grey darken--3 white--text",attrs:{"width":"100%","tile":"","depressed":"","height":"50px"},on:{"click":function($event){return _vm.$router.push('/cart')}}},[_vm._v(_vm._s(_vm.lang.backCart))])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"success grey--text text--darken-3",attrs:{"width":"100%","tile":"","depressed":"","height":"50px"},on:{"click":function($event){return _vm.createOrderConfirmation()}}},[_vm._v(_vm._s(_vm.lang.end))])],1)],1),_c('OrderCreatedDialog',{attrs:{"dialog":_vm.dialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }